import $ from 'jquery';
import ScrollReveal from 'scrollreveal';

$(document).ready(function() {

	ScrollReveal().reveal(
	'.scrollTopBar',
	{
		origin: 'top',
		distance: '0',
		delay: 3000,
		duration: 800,
		reset: false,
		useDelay: 'onload'
	});

	ScrollReveal().reveal(
	'.scrollHeadline',
	{
		origin: 'left',
		distance: '100px',
		delay: 250,
		duration: 800,
		reset: false,
		useDelay: 'onload'
	});

	ScrollReveal().reveal(
	'.scrollDesc',
	{
		origin: 'left',
		distance: '100px',
		delay: 350,
		duration: 900,
		reset: false,
		useDelay: 'onload'
	});

	ScrollReveal().reveal(
	'.scrollButton',
	{
		origin: 'left',
		distance: '100px',
		delay: 400,
		duration: 1000,
		reset: false,
		useDelay: 'onload'
	});

	ScrollReveal().reveal(
		'.scrollVideo',
		{
			origin: 'bottom',
			distance: '100px',
			delay: 400,
			duration: 1000,
			reset: false,
			useDelay: 'onload'
	});

	ScrollReveal().reveal(
		'.scrollDiv',
		{
			origin: 'bottom',
			distance: '100px',
			delay: 400,
			duration: 1000,
			reset: false,
			useDelay: 'onload'
	});
	
	ScrollReveal().reveal(
	'.scrollDescTb1',
	{
		origin: 'left',
		distance: '100px',
		delay: 350,
		duration: 1000,
		reset: false,
		useDelay: 'onload'
	});
	ScrollReveal().reveal(
	'.scrollDescTb2',
	{
		origin: 'left',
		distance: '120px',
		delay: 450,
		duration: 1000,
		reset: false,
		useDelay: 'onload'
	});
	ScrollReveal().reveal(
	'.scrollDescTb3',
	{
		origin: 'left',
		distance: '140px',
		delay: 550,
		duration: 1000,
		reset: false,
		useDelay: 'onload'
	});
	ScrollReveal().reveal(
	'.scrollDescTb4',
	{
		origin: 'left',
		distance: '160px',
		delay: 650,
		duration: 1000,
		reset: false,
		useDelay: 'onload'
	});
	ScrollReveal().reveal(
	'.scrollDescTb5',
	{
		origin: 'left',
		distance: '180px',
		delay: 750,
		duration: 1000,
		reset: false,
		useDelay: 'onload'
	});
	ScrollReveal().reveal(
	'.scrollDescTb6',
	{
		origin: 'left',
		distance: '200px',
		delay: 850,
		duration: 1000,
		reset: false,
		useDelay: 'onload'
	});

	ScrollReveal().reveal(
	'.scrollImg-left',
	{
		origin: 'left',
		distance: '100px',
		delay: 350,
		duration: 1000,
		reset: false,
		useDelay: 'onload'
	});

	ScrollReveal().reveal(
	'.scrollImg-right',
	{
		origin: 'right',
		distance: '100px',
		delay: 350,
		duration: 1000,
		reset: false,
		useDelay: 'onload'
	});

});